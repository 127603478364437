<template>
  <b-modal id="loc-tos-modal" ref="loc-tos-modal" hide-footer size="lg" scrollable header-class="no-borders" @hidden="hide">
    <div ref="loc-tos-modal-container" class="loc-tos-modal-container">
      <div class="loc-tos-modal-content" v-html="sanitizedTosDocContent" />

      <a id="loc-authorized-badge-disclosure" href="https://utahinnovationoffice.org/sandbox-customer-complaint/" target="_blank">
        <picture>
          <source
            :srcset="require('@images/authority-disclosure-badge.webp')"
            type="image/webp"
          >
          <img
            :src="require('@images/authority-disclosure-badge.png')"
            alt="authority-disclosure-badge"
          >
        </picture>
      </a>


      <div class="pb-4 mb-4">
        <p><strong>BY CLICKING THE BOX BELOW, YOU ACKNOWLEDGE THAT YOUR HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THE TERMS ABOVE.</strong></p>
        <b-checkbox
          ref="agreement-checkbox"
          v-model="agreedToTermsOfService"
          v-explicit-checked
          class="mb-3 mt-3"
          @change="agreementChanged($event)"
        >
          I have Read and Agree
        </b-checkbox>
        <div class="mb-2">
          <span v-if="showSignaturePad">Please sign here:</span>
        </div>
        <b-row
          v-if="showSignaturePad"
          ref="signature-pad-container"
          class="shadow border border-info rounded mt-4 ml-4 mb-5"
          style="width: 75%; height: 400%;"
        >
          <vue-signature-pad
            ref="signature-pad"
            width="100%"
            height="100%"
            :options="{ backgroundColor: 'rgba(248,249,250,1)' }"
            @hook:mounted="scrollDown"
          />
        </b-row>
      </div>
    </div>
    <b-row class="float-right">
      <b-button
        v-if="showSignaturePad"
        class="mb-2 mr-3"
        variant="secondary"
        aria-label="clear button"
        @click="clearSignature()"
      >
        Clear
      </b-button>
      <b-button
        class="mb-2"
        variant="primary"
        aria-label="continue button"
        :disabled="!agreedToTermsOfService"
        @click="submit()"
      >
        Continue
      </b-button>
    </b-row>
  </b-modal>
</template>

<script>
import html2pdf from 'html2pdf.js'
import VueSignaturePad from 'vue-signature-pad'
import * as DOMPurify from 'dompurify'

export default {
  name: 'LawOnCallTermsOfService',

  components: { VueSignaturePad },

  directives: {
    explicitChecked: {
      // We have to explicitly set the checked attribute on the dom element in order for html2canvas to
      // properly display a checked box when creating the pdf.
      update(el) {
        if (el.getElementsByTagName('input')[0].checked) {
          el.getElementsByTagName('input')[0].setAttribute('checked', 'checked')
        } else {
          el.getElementsByTagName('input')[0].removeAttribute('checked')
        }
      },
    },
  },

  props: {
    tosDocContent: {
      type: String,
    },
  },

  data() {
    return {
      submittingTermsOfService: false,
      agreedToTermsOfService: false,
      showSignaturePad: false,
    }
  },

  computed: {
    sanitizedTosDocContent() {
      return DOMPurify.sanitize(this.tosDocContent)
    },
  },

  watch: {
    tosDocContent() {
      this.tosDocContent = this.$props.tosDocContent
    },
  },

  methods: {
    agreementChanged(agreed) {
      this.showSignaturePad = agreed

      this.scrollDown()
    },
    clearSignature() {
      this.$refs['signature-pad'].clearSignature()
    },
    scrollDown() {
      if (typeof this.$refs['signature-pad'] !== 'undefined') {
        this.$refs['loc-tos-modal'].$refs.body.scrollTop = this.$refs['loc-tos-modal'].$refs.body.scrollHeight
      }
    },
    show() {
      this.agreedToTermsOfService = false
      this.$bvModal.show('loc-tos-modal')
    },
    hide(){
      if(!this.submittingTermsOfService) {
        //If client has not agreed, emit the close-tos to reset the tos submitter on main vue comp.
        this.$emit('close-tos')
      }else{
        this.submittingTermsOfService = false
      }

      this.agreedToTermsOfService = false
      this.showSignaturePad = false
    },
    async submit() {
      if (this.$refs['signature-pad'].isEmpty()) {
        this.$bvToast.toast('You must provide a signature to continue.', {
          title: 'Error',
          variant: 'danger',
        })
      } else {
        this.submittingTermsOfService = true
        const options = {
          margin: 2,
          pagebreak: { after: '#newpage' },
          html2canvas:  {
            scrollX: 0,
            scrollY: 50,
          },
          jsPDF: { format: 'a4' },
        }

        const worker = html2pdf().set(options).from(this.$refs['loc-tos-modal-container'])

        this.showSignaturePad = false

        this.$emit('agreed', worker)

        this.$bvModal.hide('loc-tos-modal')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.loc-tos-modal-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.loc-tos-modal-content >>> .modal-title {
  margin-top: -1em;
  margin-bottom: 1em;
  text-align: center;
}

#loc-authorized-badge-disclosure {
  align-self: center;
}

#loc-authorized-badge-disclosure picture img {
  width: 270px;
  height: 298px;
}
</style>
